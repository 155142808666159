export const colors = [
  { label: "Pearl Primed", value: "white", price: 0 },
  { label: "Cherry", value: "black", price: 1500 },
  { label: "Expresso", value: "silver", price: 1500 },
  { label: "Maple", value: "blue", price: 1500 },
  { label: "White", value: "red", price: 2500 }
];

export const interiorColors = [
  { label: "All black Figured Ash Wood Décor", value: "all_black", price: 0 },
  { label: "Black and white Dark Ash Wood Décor", value: "black_and_white", price: 1500 },
  { label: "Cream Oak Wood Décor", value: "cream", price: 1500 },
];

export const interiorLayouts = [
  { label: "Five seat interior", value: "five_seat", price: 0 },
  { label: "Six seat interior", value: "six_seat", price: 6500 },
  { label: "Seven seat interior", value: "seven_seat", price: 3500 },
];

export const models = [
  {
    key: 's',
    name: "BIFOLD",
    colors: colors,
    wheels: [
      {
        src: `${process.env.PUBLIC_URL}/wheels/model_s/model_s_wheel_1.png`,
        label: 'Mer Mel',
        value: "wheel_1",
        price: 0
      },
      {
        src: `${process.env.PUBLIC_URL}/wheels/model_s/model_s_wheel_2.png`,
        label: 'Flush',
        value: "wheel_2",
        price: 4500
      }
    ],
    types: [
      {
        label: "Full Mortise",
        value: "long_range_plus",
        specs: {
          range: 402,
          top_speed: 155,
          acceleration_time: 3.7,
          image: 'https://www.abhmfg.com/images/thumbs/0001878_full-mortise_450.jpeg'
        },
        price: 69420,
        benefits: [
          'A110HD / A110LL Aluminum Continuous Gear Hinges Full Mortise',
          'A110WT Wide Throw Aluminum Continuous Hinge Full Mortise',
          'A111HD / A111LL Aluminum Continuous Gear Hinges Full Mortise',
          'A170 Aluminum Continuous Gear Hinges Full Mortise',
          'A111WT Wide Throw Aluminum Continuous Hinge Full Mortise'
        ]
      },
      {
        label: "Half Surface",
        value: "performance",
        specs: {
          range: 387,
          top_speed: 163,
          acceleration_time: 2.3,
          image: 'https://www.abhmfg.com/images/thumbs/0001884_half-surface_450.jpeg',
        },
        price: 91990,
        benefits: [
          "A211HD Aluminum Continuous Gear Hinges Half Surface",
          "A213HD Aluminum Continuous Gear Hinges Half Surface",
          "A450HD Aluminum Continuous Gear Hinges Half Surface",
          "A550HD Aluminum Continuous Gear Hinges Half Surface",
        ]
      },
      {
        label: "Half Mortise",
        value: "plaid",
        specs: {
          range: 520,
          top_speed: 200,
          acceleration_time: 2.0,
          image: 'https://www.abhmfg.com/images/thumbs/0001885_half-mortise_450.jpeg',
        },
        price: 139990,
        benefits: [
          "A211HD Aluminum Continuous Gear Hinges Half Surface",
          "A213HD Aluminum Continuous Gear Hinges Half Surface",
          "A450HD Aluminum Continuous Gear Hinges Half Surface",
          "A550HD Aluminum Continuous Gear Hinges Half Surface",
        ]
      },
      {
        label: "Full Surface",
        value: "full_surface",
        specs: {
          range: 520,
          top_speed: 200,
          acceleration_time: 2.0,
          image: 'https://www.abhmfg.com/images/thumbs/0001887_full-surface_450.jpeg',
        },
        price: 139990,
        benefits: [
          "A211HD Aluminum Continuous Gear Hinges Half Surface",
          "A213HD Aluminum Continuous Gear Hinges Half Surface",
          "A450HD Aluminum Continuous Gear Hinges Half Surface",
          "A550HD Aluminum Continuous Gear Hinges Half Surface",
        ]
      },
      {
        label: "Behavioral Health Double Swing",
        value: "behaviour",
        specs: {
          range: 520,
          top_speed: 200,
          acceleration_time: 2.0,
          image: 'https://www.abhmfg.com/images/thumbs/0001900_behavioral-health-double-swing_450.jpeg',
        },
        price: 139990,
        benefits: [
          "A211HD Aluminum Continuous Gear Hinges Half Surface",
          "A213HD Aluminum Continuous Gear Hinges Half Surface",
          "A450HD Aluminum Continuous Gear Hinges Half Surface",
          "A550HD Aluminum Continuous Gear Hinges Half Surface",
        ]
      },
      {
        label: "Through Wire Prep Easy Access",
        value: "through",
        specs: {
          range: 520,
          top_speed: 200,
          acceleration_time: 2.0,
          image: 'https://www.abhmfg.com/images/thumbs/0002216_through-wire-prep-easy-access_450.jpeg',
        },
        price: 139990,
        benefits: [
          "A211HD Aluminum Continuous Gear Hinges Half Surface",
          "A213HD Aluminum Continuous Gear Hinges Half Surface",
          "A450HD Aluminum Continuous Gear Hinges Half Surface",
          "A550HD Aluminum Continuous Gear Hinges Half Surface",
        ]
      }
    ],
    interiorColors: interiorColors
  },
  {
    key: 'x',
    name: "SLIDER",
    colors: colors,
    wheels: [
      {
        src: `${process.env.PUBLIC_URL}/wheels/model_x/model_x_wheel_1.png`,
        label: 'Mer mel',
        value: "wheel_1",
        price: 0
      },
      {
        src: `${process.env.PUBLIC_URL}/wheels/model_x/model_x_wheel_2.png`,
        label: 'Flush',
        value: "wheel_2",
        price: 5500
      }
    ],
    types: [
      {
        label: "Full Mortise",
        value: "long_range_plus",
        specs: {
          range: 402,
          top_speed: 155,
          acceleration_time: 3.7,
          image: 'https://www.abhmfg.com/images/thumbs/0001878_full-mortise_450.jpeg'
        },
        price: 69420,
        benefits: [
          'A110HD / A110LL Aluminum Continuous Gear Hinges Full Mortise',
          'A110WT Wide Throw Aluminum Continuous Hinge Full Mortise',
          'A111HD / A111LL Aluminum Continuous Gear Hinges Full Mortise',
          'A170 Aluminum Continuous Gear Hinges Full Mortise',
          'A111WT Wide Throw Aluminum Continuous Hinge Full Mortise'
        ]
      },
      {
        label: "Half Surface",
        value: "performance",
        specs: {
          range: 387,
          top_speed: 163,
          acceleration_time: 2.3,
          image: 'https://www.abhmfg.com/images/thumbs/0001884_half-surface_450.jpeg',
        },
        price: 91990,
        benefits: [
          "A211HD Aluminum Continuous Gear Hinges Half Surface",
          "A213HD Aluminum Continuous Gear Hinges Half Surface",
          "A450HD Aluminum Continuous Gear Hinges Half Surface",
          "A550HD Aluminum Continuous Gear Hinges Half Surface",
        ]
      },
      {
        label: "Half Mortise",
        value: "plaid",
        specs: {
          range: 520,
          top_speed: 200,
          acceleration_time: 2.0,
          image: 'https://www.abhmfg.com/images/thumbs/0001885_half-mortise_450.jpeg',
        },
        price: 139990,
        benefits: [
          "A211HD Aluminum Continuous Gear Hinges Half Surface",
          "A213HD Aluminum Continuous Gear Hinges Half Surface",
          "A450HD Aluminum Continuous Gear Hinges Half Surface",
          "A550HD Aluminum Continuous Gear Hinges Half Surface",
        ]
      },
      {
        label: "Full Surface",
        value: "full_surface",
        specs: {
          range: 520,
          top_speed: 200,
          acceleration_time: 2.0,
          image: 'https://www.abhmfg.com/images/thumbs/0001887_full-surface_450.jpeg',
        },
        price: 139990,
        benefits: [
          "A211HD Aluminum Continuous Gear Hinges Half Surface",
          "A213HD Aluminum Continuous Gear Hinges Half Surface",
          "A450HD Aluminum Continuous Gear Hinges Half Surface",
          "A550HD Aluminum Continuous Gear Hinges Half Surface",
        ]
      },
      {
        label: "Behavioral Health Double Swing",
        value: "behaviour",
        specs: {
          range: 520,
          top_speed: 200,
          acceleration_time: 2.0,
          image: 'https://www.abhmfg.com/images/thumbs/0001900_behavioral-health-double-swing_450.jpeg',
        },
        price: 139990,
        benefits: [
          "A211HD Aluminum Continuous Gear Hinges Half Surface",
          "A213HD Aluminum Continuous Gear Hinges Half Surface",
          "A450HD Aluminum Continuous Gear Hinges Half Surface",
          "A550HD Aluminum Continuous Gear Hinges Half Surface",
        ]
      },
      {
        label: "Through Wire Prep Easy Access",
        value: "through",
        specs: {
          range: 520,
          top_speed: 200,
          acceleration_time: 2.0,
          image: 'https://www.abhmfg.com/images/thumbs/0002216_through-wire-prep-easy-access_450.jpeg',
        },
        price: 139990,
        benefits: [
          "A211HD Aluminum Continuous Gear Hinges Half Surface",
          "A213HD Aluminum Continuous Gear Hinges Half Surface",
          "A450HD Aluminum Continuous Gear Hinges Half Surface",
          "A550HD Aluminum Continuous Gear Hinges Half Surface",
        ]
      }
    ],
    interiorColors: interiorColors,
    interiorLayouts: interiorLayouts
  }
];

export const initialConfig = {
  's': {
    car_type: "mer_mel",
    model: "s",
    color: "white",
    wheels: "wheel_1",
    interior_color: "all_black"
  },
  'x': {
    car_type: "flush",
    model: "x",
    color: "white",
    wheels: "wheel_1",
    interior_color: "all_black",
    interior_layout: "five_seat"
  }
};